
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  data() {
    return {
      tableData: [
        {
          _id: "1",
          school: "SMA TKI 1",
          title: "",
          date: moment(new Date()).format("DD MMMM YYYY"),
          total: 1000000,
          paymentStatus: "",
          orderStatus: "CANCELED",
          checkData: "PREVIEW",
        },
      ],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      url: "",

      detail: {
        // name: "",
        // createTime: "2022-02-15T13:33:33",
        // creatorId: "",
        // deleteBy: "",
        // deleteTime: "",
        // description: "",
        // discount: 0,
        // editorId: "",
        // imageshow: "",
        // imgUrl: [],
        // isDelete: false,
        // price: 0,
        // productCatagory: "",
        // stock: 0,
        // updateTime: "",
        // _id: "",
        checkData: "PREVIEW",
        date: "05 December 2022",
        orderStatus: "CANCELED",
        paymentStatus: "",
        school: "SMA TKI 1",
        title: "",
        total: 1000000,
        _id: "1",
      },
      detailImage: {
        images1: "",
        images2: "",
        images3: "",
        images4: "",
        images5: "",
      },
    };
  },

  beforeMount() {
    this.getDetail();
  },

  methods: {
    changeImage(item) {
      console.log(item);
      //   this.detail.imageshow = item;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    hexToBase64(str) {
      return btoa(
        String.fromCharCode.apply(
          null,
          str
            .replace(/\r|\n/g, "")
            .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
            .replace(/ +$/, "")
            .split(" ")
        )
      );
    },
    getDetail() {
      store.dispatch(Actions.VERIFY_AUTH);

      this.isLoading = true;

      setTimeout(() => {
        let detail = this.tableData.find(
          (item: any) => item.id == this.$route.params._id
        );
        console.log(detail);
        this.isLoading = false;
      }, 1000);
      const toast = useToast();
      const $route = useRoute();

      //   ApiService.getWithoutSlug(
      //     "crmv2/main_order/product/" + this.$route.params._id
      //   )
      //     .then((res) => {
      //       // this.defaultItem = response.data.data.result;
      //       this.detail = res.data;
      //       // this.detail.imageshow = res.data.imgUrl[0];

      //       if (res.data.imgUrl) {
      //         let jumlahFoto = res.data.imgUrl.length;
      //         if (jumlahFoto > 0) {
      //           for (let i = 0; i < jumlahFoto; i++) {
      //             let fotoparam = res.data.imgUrl[i];
      //             // console.log(fotoparam);

      //             ApiService.getWithoutSlug(
      //               "crmv2/main_image/image/get/base64/" + fotoparam
      //             )
      //               .then((res) => {
      //                 // console.log(res.data);
      //                 switch (i) {
      //                   case 0:
      //                     this.detail.imageshow =
      //                       "data:image/jpg;base64," + res.data;
      //                     this.detailImage.images1 =
      //                       "data:image/jpg;base64," + res.data;
      //                     break;
      //                   case 1:
      //                     this.detailImage.images2 =
      //                       "data:image/jpg;base64," + res.data;
      //                     break;
      //                   case 2:
      //                     this.detailImage.images3 =
      //                       "data:image/jpg;base64," + res.data;
      //                     break;
      //                   case 3:
      //                     this.detailImage.images4 =
      //                       "data:image/jpg;base64," + res.data;
      //                     break;
      //                   case 4:
      //                     this.detailImage.images5 =
      //                       "data:image/jpg;base64," + res.data;
      //                     break;
      //                 }

      //                 // this.urlimage1 = URL.createObjectURL(res.data);
      //               })
      //               .catch((e) => {
      //                 this.detail.imageshow =
      //                   "/media/no-image/No_Image_Horizontal_TKI.png";
      //                 toast.error(e.res.data.detail);
      //                 this.isLoading = false;
      //               });
      //           }
      //         }
      //       } else {
      //         this.detail.imageshow =
      //           "/media/no-image/No_Image_Horizontal_TKI.png";
      //       }

      //       console.log(this.detail);

      //       this.isLoading = false;
      //     })
      //     .catch((e) => {
      //       toast.error(e.res.data.detail);

      //       this.isLoading = false;
      //     });
    },
  },
});
